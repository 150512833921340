import Country from '/utils/proto/country'
import Language from '/utils/proto/language'
import Currency from '/utils/proto/currency'
import Query from '/utils/proto/query'
import Storage from '/utils/proto/storage'
import Price from '/utils/proto/price'
import Login from '/utils/proto/login'
import LangText from '/utils/common/language'

const country = new Country()
const language = new Language()
const currency = new Currency()
const query = new Query()
const storage = new Storage()
const price = new Price()
const login = new Login()
const langText = new LangText()

export default (ctx, inject) => {
    /* 获取国家 */
    const countryR = country.initial('', ctx);
    /* 获取语言 */
    const languageR = language.initial('', ctx);
    /* 获取货币 */
    const currencyR = currency.initial('', ctx);
    /* 获取地址栏参数 */
    const queryR = query.initial('', ctx);
    /* 获取缓存 */
    const storageR = storage.initial('', ctx);
    /* 货币金额置换 */
    const priceFn = (v, c) => {
        return price.initial({ price: v, currency: c }, ctx)
    };
    /* 翻译 */
    const translateFn = (v, c) => {
        // return v
        return langText.translate(v, c, ctx)
    };
    /* 是否登录 */
    const loginFn = (v, c) => {
        return login.initial(v, ctx)
    };
    // if(process.client){ //客户端数据获取
    //     if(!storageR.get('country')){
    //         storageR.set('country',countryR,true)
    //     }
    //     if(!storageR.get('currency')){
    //         storageR.set('currency',currencyR,true)
    //     }
    //     if(!storageR.get('language')){
    //         storageR.set('language',languageR,true)
    //     }
    // }
    inject('storage', storageR)
    inject('country', countryR)
    inject('language', languageR)
    inject('currency', currencyR)
    inject('query', queryR)
    inject('price', priceFn)
    inject('translate', translateFn)
    inject('login', loginFn)
}