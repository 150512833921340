// 浏览器指纹
import Fingerprint2 from 'fingerprintjs2'

export default (ctx, inject) => {
    const getFingerprint = new Promise((resolve, reject) => {
        Fingerprint2.get({}, function (components) {
            const values = components.map(function (component) {
                return component.value
            });
            const murmur = Fingerprint2.x64hash128(values.join(''), 31);
            resolve(murmur)
        });
    })
    const setFingerprint = () => {
        // let deviceId = ctx.$cookies.get('deviceId');
        let deviceId = ctx.$storage.get('deviceId');
        if(!deviceId) {
            getFingerprint.then(res => {
                const t = new Date().getTime();
                deviceId = `ls${res}ts${t}`;
                // deviceId = `ls${res}ts1695629420973`;
                // ctx.$cookies.set('deviceId', deviceId)
                if (process.client) {
                    ctx.$storage.set('deviceId',deviceId)
                } else{
                    ctx.store.commit('national/setDeviceId', deviceId)
                }

            })
        }
    };
    setFingerprint()
}